import EasyMDE from "easymde/src/js/easymde"

$(document).ready(function () {
    $('.easymde-editor').each(function () {
        var options = {
            minHeight: "4rem"
        };
        options = $.extend({element: $(this).get(0)}, options, $(this).data('options'));
        new EasyMDE(options);
    });
});
