import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["selector", "tags", "fellows"]

    get selected() {
        return this.selectorTarget.value
    }

    connect() {
        this.changeSelector()
    }

    changeSelector() {
        this.tagsTarget.style.display = this.displayFor("tags")
        this.fellowsTarget.style.display = this.displayFor("fellows")
    }

    displayFor(type) {
        return type === this.selected ? "block" : "none"
    }
}
